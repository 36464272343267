body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h3 {
  text-decoration: underline;
}

.drawing-page .container {
  text-align: center;
  padding: 0;
  margin: auto;
  display: block;
}

.start-page {
  text-align: center;
}

.add-line {
  text-align: center;
  margin: auto;
  display: block;
}

.direction-arrows {
  text-align: center;
}

.arrow {
  text-decoration: none;
  color: black;
}

.arrow-clicked {
  text-decoration: none;
  color: blue;
}

.arrows {
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 10px;
}

.arrows a {
  display: block;
  width: fit-content;
  height: fit-content;
}

.btn-primary {
  width: 50%;
}

.length-input {
  width: 50%;
}

.error-line {
  color: red;
}

.success-line {
  color: green;
}
